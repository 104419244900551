$box-shadow-1: 0 0 20px 0px rgba(0, 0, 0, 0.1);
$box-shadow-2: 0 0 20px 0px rgba(0, 0, 0, 0.2);
$box-shadow-3: 0 0 20px 0px rgba(0, 0, 0, 0.3);

@mixin checkout-element {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: $dim-checkout-max-width;
    padding-bottom: 2rem;
}

@mixin not-touch {
    @media (hover: hover) and (pointer: fine) {
        @content;
    }
}
