.explorer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &-box {
        width: 100%;
        max-width: 600px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem;
    }

    &__image {
        display: flex;

        &-photo {
            width: 100%;
            height: 100%;
        }
    }

    &__text {
        font-size: 2.5rem;
        text-align: center;
    }
}