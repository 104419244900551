@import "../../scss/variables";

.checkout-form {
    @include checkout-element;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    &__title {
        font-size: 3rem;
        font-weight: bold;
        color: $color-primary;
        text-align: center;
        margin: 4rem 0 2rem;
    }

    &__section {
        background-color: $color-white;
        box-shadow: 0 0 1rem 0 rgba(black, 0.1);
        padding: 2rem;
        display: flex;
        flex-direction: column;

        &-row {
            display: flex;
            align-items: flex-start;

            & > :first-child {
                margin-right: 1rem;
            }
            & > :last-child {
                margin-left: 1rem;
            }
        }
    }

    &__button {
        display: flex;
        justify-content: flex-end;
        margin: 2rem 0;
    }
}
