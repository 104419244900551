@import "../../scss/variables";

.summary-customer-and-address {
    @include checkout-element;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    &__title {
        font-size: 3rem;
        font-weight: bold;
        color: $color-primary;
        text-align: center;
        margin: 4rem 0 2rem;
    }

    &__section {
        background-color: $color-white;
        box-shadow: 0 0 1rem 0 rgba(black, 0.1);
        display: flex;
        flex-direction: column;

        &-field {
            display: flex;
            flex-direction: column;
            padding: 1rem;

            @include breakpoint-up-checkout {
                flex: 0 0 50%;

                &.full {
                    flex: 0 0 100%;
                }
            }

            &-label {
                font-size: 1.3rem;
                letter-spacing: 0.1em;
            }

            &-value {
                font-size: 1.6rem;
                padding-top: 0.5rem;
                font-weight: bold;
            }
        }

        &-content {
            padding: 1rem;
            display: flex;
            flex-direction: column;

            @include breakpoint-up-checkout {
                flex-direction: row;
                flex-wrap: wrap;
            }
        }
    }

    &__button {
        width: 100%;
        height: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-size: 1.4rem;
        color: $color-primary;
        font-weight: bold;
        letter-spacing: 0.15em;
        cursor: pointer;
        border-top: 1px solid $color-grey-0;
    }
}
