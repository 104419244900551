@import "../../scss/variables";

.product-and-shipment {
    @include checkout-element;
    align-items: flex-end;

    &__title {
        width: 100%;
        text-align: center;
        font-size: 3rem;
        font-weight: bold;
        color: $color-primary;
        margin-bottom: 2rem;
    }

    &__products {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &__product {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: $color-white;
        margin-bottom: 2rem;
        box-shadow: 0 0 1rem 0 rgba(black, 0.1);

        @include breakpoint-up-checkout {
            flex-direction: row;
            align-items: center;
        }

        &-photo {
            width: 100%;
            padding-top: 100%;
            position: relative;
            background-color: $color-grey-0;

            @include breakpoint-up-xs {
                padding-top: 50%;
            }

            @include breakpoint-up-checkout {
                padding-top: 50%;
                width: 30%;
            }

            &-img {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                object-fit: cover;

                @include breakpoint-up-checkout {
                    object-position: 50% 60%;
                }
            }

            &-placeholder {
                @include ff-icon;
                color: $color-grey-4;
                font-size: 5rem;
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        &-info {
            width: 100%;
            display: flex;
            flex-direction: column;

            @include breakpoint-up-checkout {
                width: 70%;
            }
        }

        &-label {
            text-align: left;
            padding: 1rem 2rem;
            font-size: 2.4rem;
            font-weight: bold;
            color: $color-primary;

            @include breakpoint-up-checkout {
                text-align: right;
                padding: 2rem;
            }
        }

        &-controls {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 1rem;

            @include breakpoint-up-xs {
                flex-direction: row;
            }
        }

        &-value {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 0 1rem;

            @include breakpoint-up-xs {
                flex-direction: column;
                justify-content: flex-start;
            }

            &-label {
                font-size: 1.2rem;
                letter-spacing: 0.1em;
                text-align: center;
                height: 2rem;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 50%;
                padding: 0 1rem 0 0;

                @include breakpoint-up-xs {
                    width: unset;
                    padding: 0;
                }
            }

            &-price {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                height: 4rem;

                @include breakpoint-up-xs {
                    align-items: center;
                    margin-top: 1rem;
                }
            }

            &-normal {
                font-size: 1.6rem;
                margin: 1rem 0;
                font-weight: bold;
                height: 2rem;
                display: flex;
                align-items: center;
                color: $color-primary;
                text-align: center;

                &.is-discounted {
                    font-size: 1rem;
                    text-decoration: line-through;
                    font-weight: normal;
                    height: 1rem;
                    line-height: 1em;
                    margin: 0;
                    color: $color-grey-7;
                }
            }

            &-discounted {
                font-size: 1.6rem;
                font-weight: bold;
                height: 2rem;
                display: flex;
                align-items: center;
                color: $color-primary;
            }
        }

        &-counter {
            display: flex;
            align-items: stretch;
            height: 4rem;
            margin: 1rem 0;

            &-button {
                @include ff-icon;
                border: 2px solid $color-primary;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 4rem;
                height: 4rem;
                color: $color-primary;
                user-select: none;
                cursor: pointer;
                transition: all 0.1s ease-out;

                &:hover {
                    @include not-touch {
                        background-color: rgba($color-primary, 0.7);
                        color: $color-white;
                    }
                }

                &:active {
                    background-color: $color-primary;
                    color: $color-white;
                }

                &:first-child {
                    border-radius: 1rem 0 0 1rem;
                }

                &:last-child {
                    border-radius: 0 1rem 1rem 0;
                }

                &.is-disabled {
                    color: rgba($color-primary, 0.3);

                    &:active,
                    &:hover {
                        background-color: $color-white;
                        color: rgba($color-primary, 0.3);
                        cursor: not-allowed;
                    }
                }
            }

            &-value {
                width: 4rem;
                height: 4rem;
                border-top: 2px solid $color-primary;
                border-bottom: 2px solid $color-primary;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 2rem;
                color: $color-primary;
            }
        }
    }

    &__shipments {
        width: 100%;
        max-width: 360px;
        background-color: $color-white;
        box-shadow: 0 0 1rem 0 rgba(black, 0.1);
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 2rem;
    }

    &__shipment {
        width: 100%;
        padding: 1rem 2rem;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:not(:last-child) {
            border-bottom: 1px solid $color-grey-0;
        }

        &.is-disabled {
            cursor: not-allowed;
        }

        &-option {
            width: 3rem;
            height: 3rem;
            border-radius: 1.5rem;
            border: 2px solid $color-grey-2;
            position: relative;
            transition: all 0.2s ease-out;

            .is-selected & {
                border-color: $color-primary;
            }

            &::before {
                content: "check";
                @include ff-icon;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                user-select: none;
                font-size: 2rem;
                opacity: 0;
                transition: all 0.2s ease-out;

                .is-selected & {
                    color: $color-primary;
                    opacity: 1;
                }
            }
        }

        &-label {
            padding: 0 1rem;
            flex: 1 0 50px;
            font-size: 1.6rem;

            .is-disabled & {
                color: $color-grey-2;
            }
        }

        &-price {
            height: 4rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;

            &-value {
                font-size: 1.6rem;
                color: $color-grey-7;
                font-weight: bold;
                transition: all 0.2s ease-out;

                .is-selected & {
                    color: $color-primary;
                }

                &.is-discounted {
                    color: $color-grey-7;
                    font-size: 1rem;
                    font-weight: normal;
                    text-decoration: line-through;
                }

                .is-disabled & {
                    color: $color-grey-2;
                }
            }

            &-discounted {
                font-size: 1.6rem;
                color: $color-grey-7;
                font-weight: bold;
                transition: all 0.2s ease-out;
                margin-bottom: 1rem;

                .is-selected & {
                    color: $color-primary;
                }

                .is-disabled & {
                    color: $color-grey-2;
                }
            }
        }
    }

    &__discounts {
        width: 100%;
        max-width: 360px;
        background-color: $color-white;
        box-shadow: 0 0 1rem 0 rgba(black, 0.1);
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 2rem;
    }

    &__discount {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 1rem 2rem;
        border-bottom: 1px solid $color-grey-0;

        &-label {
            font-size: 1.6rem;
            flex: 1 0 100px;
            padding-right: 2rem;
        }

        &-value {
            font-size: 1.6rem;
            color: $color-grey-7;
            font-weight: bold;
        }
    }

    &__discount {
        &-add-button {
            width: 100%;
            display: flex;
            align-items: center;
            padding: 1rem 2rem;
            font-size: 1.4rem;
            color: $color-primary;
            letter-spacing: 0.1em;
            font-weight: bold;
            cursor: pointer;

            i {
                @include ff-icon;
                height: 3rem;
                width: 3rem;
                border-radius: 1.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid $color-primary;
                color: $color-primary;
                text-align: center;
                margin-right: 1rem;
                font-size: 2rem;
            }
        }

        &-form {
            width: 100%;
            display: flex;
            padding: 1rem 2rem;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;

            &-close {
                @include ff-icon;
                width: 3rem;
                height: 3rem;
                border-radius: 1.5rem;
                border: 2px solid $color-grey-3;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 2rem;
                color: $color-grey-5;
                margin-right: 1rem;
                flex: 0 0 3rem;
                cursor: pointer;
            }

            &-input {
                height: 4rem;
                border: 2px solid $color-primary;
                appearance: none;
                border-radius: 1rem 0 0 1rem;
                padding: 0 1rem;
                flex: 1 0 100px;
                font-size: 1.6rem;
                width: 100%;
                margin: 0;

                &:focus {
                    outline: none;
                }

                &:disabled {
                    color: $color-grey-5;
                }
            }

            &-button {
                flex: 0 0 80px;
                height: 4rem;
                background-color: $color-white;
                border: 2px solid $color-primary;
                border-radius: 0 1rem 1rem 0;
                border-left: none;
                text-transform: uppercase;
                letter-spacing: 0.1em;
                font-weight: bold;
                color: $color-primary;
                cursor: pointer;
                margin: 0;

                &:focus {
                    outline: none;
                }

                &:hover {
                    @include not-touch {
                        background-color: rgba($color-primary, 0.7);
                        color: $color-white;
                    }
                }

                &:active {
                    background-color: $color-primary;
                    color: $color-white;
                }

                &:disabled {
                    color: rgba($color-primary, 0.3);

                    &:active,
                    &:hover {
                        background-color: $color-white;
                        color: rgba($color-primary, 0.3);
                        cursor: not-allowed;
                    }
                }
            }

            &-error {
                flex: 0 0 100%;
                width: 100%;
                text-align: right;
                font-size: 1.4rem;
                color: $color-secondary;
                margin-top: 0.5rem;
            }
        }
    }

    &__summary {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 1rem 2rem;

        &-row {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
        }

        &-label {
            text-transform: uppercase;
            font-size: 1.4rem;
            flex: 1 0 100px;
            text-align: right;
            padding: 1rem 2rem;

            .is-discounted & {
                text-decoration: line-through;
                font-size: 1rem;
                color: $color-grey-7;
            }
        }

        &-value {
            font-size: 1.6rem;
            font-weight: bold;
            color: $color-primary;

            .is-discounted & {
                text-decoration: line-through;
                font-size: 1.2rem;
                color: $color-grey-7;
            }
        }
    }

    &__footer {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 2rem;
    }
}
