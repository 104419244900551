@import "../scss/variables";
.form__checkbox {
    display: flex;
    align-items: center;
    width: 90%;
    margin: 1rem 0;

    &-input {
        height: 3rem;
        width: 3rem;
        opacity: 0;
        cursor: pointer;
        flex: 0 0 3rem;
    }

    &-square {
        height: 3rem;
        width: 3rem;
        position: absolute;
        border: 2px solid $color-primary;
        border-radius: 0.5rem;
        @include ff-icon;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgba($color-primary, 0);
        transition: color 0.1s ease-out;

        &.is-checked {
            color: $color-primary;
        }
    }

    &-label {
        font-size: 1rem;
        font-weight: bold;
        color: $color-grey-7;
        text-transform: uppercase;
        cursor: pointer;
        padding-left: 1rem;

        a {
            color: $color-primary;
        }
    }
}
