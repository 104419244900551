@import "../../scss/variables";

.terms {
    @include checkout-element;
    padding: 2rem;
    align-items: flex-start;

    &__button {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 3rem 0 5rem;
    }
}
