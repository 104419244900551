@import "../scss/variables";

.text-field {
    &__input {
        width: 100%;
        height: 4rem;
        font-size: 1.6rem;
        border: 1px solid $color-grey-4;
        border-radius: 0.5rem;
        padding: 0 1rem;

        &:focus {
            border-color: $color-primary;
            outline: none
        }
    }
}
