html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-size: 10px;
    min-width: 320px;
    -webkit-tap-highlight-color: transparent;
}

body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 10px;
    width: 100%;
    height: 100%;
    min-width: 320px;
    background-color: $color-bg;
    font-family: Arial, Helvetica, sans-serif;
    
}

#root {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

* {
    box-sizing: border-box;
}

.router-home {
    width: 100%;
    height: 100%;
}

