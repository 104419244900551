@import "../scss/variables";

.text-switch {
    display: flex;
    align-items: center;

    &__option {
        font-size: 1.4rem;
        font-weight: bold;
        border: 2px solid $color-primary;
        padding: 0 1rem;
        display: flex;
        align-items: center;
        height: 4rem;
        cursor: pointer;
        transition: all 0.2s ease-out;

        &:first-child {
            border-radius: 1rem 0 0 1rem;
        }

        &:last-child {
            border-radius: 0 1rem 1rem 0;

            &:first-child {
                border-radius: 1rem;
            }
        }

        &:hover {
            background-color: rgba($color-primary, 0.3);
        }

        &.is-selected {
            background-color: $color-primary;
            color: $color-white;
        }
    }
}
