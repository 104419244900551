@import "../scss/variables";

.field-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 1rem 0;

    &__title {
        font-size: 1.5rem;
        margin: 0.25rem 0;
    }

    &__description {
        margin: 0.25rem 0;
        font-size: 1.2rem;
    }

    &__errors {
        margin: 0.25rem 0;
        font-size: 1.2rem;
        color: red;
    }
}
