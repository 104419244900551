.regulamin {
    padding: 2rem;
    padding-top: 12rem;
    display: flex;
    justify-content: center;

    &__center {
        width: 100%;
        max-width: 800px;
    }

    &__header {
        font-size: 1.5rem;
        text-transform: uppercase;
        font-weight: bold;
    }

    &__text {
        font-size: 1.4rem;
        line-height: 1.9rem;
    }
}
