@import "../../scss/variables";

.order {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 10rem 2rem 2rem;
    // min-height: 100%;

    &__title {
        @include checkout-element;
        text-align: center;
        font-size: 3rem;
        text-transform: uppercase;
        font-weight: bold;
        color: $color-black;

        .smaller {
            font-size: 2rem;
        }
    }

    &__subtitle {
        @include checkout-element;
        text-align: center;
        font-size: 3rem;
        font-weight: bold;
        color: $color-primary;
        margin: 4rem 0 2rem;
    }

    &__status {
        padding: 1rem 2rem;
        background-color: $color-primary;
        font-size: 2rem;
        margin: 2rem 0 4rem;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 0.05em;
        color: $color-white;
        border-radius: 1rem;
    }

    &__description {
        @include checkout-element;
        padding: 2rem;
        font-size: 1.6rem;
        text-align: center;
        word-break: break-word;
    }

    &__payments {
        @include checkout-element;
        background-color: $color-white;
        box-shadow: 0 0 1rem 0 rgba(black, 0.1);
        padding: 2rem;
        display: flex;
        flex-direction: column;

        table {
            width: 100%;
            border-collapse: collapse;

            thead {
                tr {
                    background-color: $color-primary;
                    color: $color-white;
                    font-size: 1.6rem;

                    th {
                        padding: 1rem 0;
                    }
                }
            }

            tbody {
                tr {
                    &:nth-child(2n) {
                        background-color: $color-grey-0;
                    }

                    td {
                        padding: 1rem 0;
                        text-align: center;
                        font-size: 1.4rem;
                    }
                }
            }
        }

        &-empty {
            font-size: 1.4rem;
            text-align: center;
            padding: 2rem;
        }

        &-button {
            display: flex;
            justify-content: center;
        }
    }

    &__state {
        @include checkout-element;

        &-container {
            display: flex;
            flex-direction: column;
            padding-bottom: 2rem;

            &__cell {
                display: flex;
                position: relative;
                align-items: center;
                padding-top: 2rem;

                &::before {
                    content: "";
                    position: absolute;
                    width: 1px;
                    height: 100%;
                    background-color: $color-grey-1;
                    bottom: 1rem;
                    left: 0;                    
                }

                &.is-ok::before {
                    background-color: $color-primary;
                }

                &:first-child {
                    &::before {
                        display: none;
                    }
                }

                &-dot {
                    position: absolute;
                    left: -1.25rem;
                    width: 1.5rem;
                    height: 1.5rem;
                    background-color: $color-grey-1;
                    border-radius: 50%;
                    margin: 0 0.5rem;
                    z-index: 2;

                    .is-ok & {
                        background-color: $color-primary;
                    }
                }

                &-text {
                    color: $color-grey-1;
                    font-size: 2.2rem;
                    padding-left: 2rem;

                    .is-ok & {
                        color: $color-primary;
                    }
                }
            }
        }
    }
}
