.preview {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__center {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: $dim-breakpoint-lg;
    }

    &__header {
        font-size: 3rem;
        color: $color-primary;
        font-weight: 600;
        width: 100%;
        display: flex;
        justify-content: center;
        padding-bottom: 2rem;
    }

    &__subheader {
        padding: 0 2rem;
        font-size: 1.5rem;
        text-align: center;
        color: $color-grey-1;
        padding-bottom: 3rem;
    }

    &-columns {
        padding: 1rem;
        display: flex;
        flex-direction: column;

        @media (min-width: $dim-breakpoint-sm) {
            flex-direction: row;
            flex-wrap: wrap;
        }

        &__cell {
            display: flex;
            padding: 1rem;

            @media (min-width: $dim-breakpoint-sm) {
                flex: 0 0 50%;
            }

            @media (min-width: $dim-breakpoint-md) {
                flex: 0 0 1/3 * 100%;
            }

            @media (min-width: 1050px) {
                flex: 0 0 25%;
            }

            @media (min-width: $dim-breakpoint-lg) {
                flex: 0 0 20%;
            }

            &-wrapper {
                display: flex;
                width: 100%;
                cursor: pointer;
                border-radius: 1rem;

                &:hover {
                    box-shadow: $box-shadow-1;
                }
            }

            &-photo {
                width: 100%;
                height: 100%;
                border-radius: 1rem;
            }
        }
    }

    &__buttons {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    &__bottomline {
        &-small {
            width: 15%;
            height: 1px;
            background-color: $color-grey-1;
            margin-bottom: 3rem;
        }

        &-big {
            width: 90%;
            height: 1px;
            background-color: $color-grey-1;
            margin-bottom: 3rem;
        }
    }

    &__bottomcontent {
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: 2.5rem;

        &-header {
            padding: 0 2rem;
            font-size: 3rem;
            font-weight: 600;
            color: $color-primary;
            padding-bottom: 3rem;
            text-align: center;
            line-height: 3.5rem;
        }

        &-description {
            font-size: 1.65rem;
            color: $color-grey-1;
            width: 80%;
            text-align: center;
            padding-bottom: 2rem;
        }

        &-buttons {
            display: flex;
            padding-bottom: 4rem;
        }
    }

    &__player {
        position: fixed;
        display: flex;
        top: 0;
        z-index: 6;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: $color-grey-1;
        opacity: 1;
    }

    &__photo {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;

        &-image {
            width: 80%;
        }
    }

    &__close {
        @include ff-icon;
        position: absolute;
        font-size: 5rem;
        top: 2rem;
        right: 2rem;
        color: white;
        cursor: pointer;
        background-color: $color-grey-1;
        opacity: 0.4;
        &:hover {
            opacity: 0.8;
        }
    }

    &__button {
        @include ff-icon;
        color: white;
        position: fixed;
        display: flex;
        font-size: 8rem;
        align-items: center;
        cursor: pointer;
        background-color: $color-grey-1;
        opacity: 0.4;

        &:hover {
            opacity: 0.8;
        }

        &.left {
            left: 1rem;
        }

        &.right {
            right: 1rem;
        }
    }
}
