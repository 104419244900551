.footer {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    &-column {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (min-width: $dim-breakpoint-sm) {
            flex-direction: row;
            justify-content: space-evenly;
            align-items: flex-start;
        }

        &__cell {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            padding: 2rem;
            line-height: 2.5rem;

            @media (min-width: $dim-breakpoint-sm) {
                flex: 0 0 40%;
            }

            &-header {
                font-size: 1.7rem;
                font-weight: 600;
                text-align: center;
                padding-bottom: 1.5rem;
            }

            &-description {
                font-size: 1.4rem;
                text-align: center;
                padding-bottom: 1.5rem;
            }

            &-buttons {
                display: flex;
            }
        }
    }

    &__logo {
        display: flex;
        width: 100%;
        max-width: 250px;
        justify-content: center;
        cursor: pointer;

        &-wrapper {
            width: 100%;
            display: flex;
            padding-bottom: 3rem;
            padding-top: 3rem;

            justify-content: center;

            @media (min-width: $dim-breakpoint-md) {
                justify-content: flex-end;
            }
        }

        &-image {
            width: 100%;
            height: 100%;
        }
    }

    &__links {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2rem;

        a {
            color: $color-primary;
            font-size: 1.2rem;
            padding: 0.5rem;
        }
    }
}
