@import "../../scss/variables";

.summary-products-and-shipment {
    @include checkout-element;
    padding: 0;
    background-color: $color-white;
    box-shadow: 0 0 1rem 0 rgba(black, 0.1);
    align-items: flex-start;

    &__title {
        width: 100%;
        text-align: center;
        font-size: 3rem;
        font-weight: bold;
        color: $color-primary;
        margin-bottom: 2rem;
    }

    &__products {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    &__product {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding: 2rem;
        border-bottom: 1px solid $color-grey-0;

        @include breakpoint-up-checkout {
            flex-direction: row;
            align-items: baseline;
            justify-content: space-between;
        }

        &-label {
            font-size: 1.6rem;
            text-align: left;
            padding-bottom: 2rem;

            @include breakpoint-up-checkout {
                flex: 0 0 auto;
                padding: 0 2rem 0 0;
            }
        }

        &-order {
            font-size: 1.4rem;
            text-align: right;
        }
    }

    &__shipment {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 2rem;
        justify-content: space-between;
        border-bottom: 1px solid $color-grey-0;

        @include breakpoint-up-checkout {
            justify-content: flex-end;
        }

        &-label {
            text-align: left;
            padding-right: 2rem;
            font-size: 1.4rem;

            @include breakpoint-up-checkout {
                text-align: right;
            }
        }

        &-value {
            font-size: 1.4rem;
            text-align: right;
            font-weight: bold;

            @include breakpoint-up-checkout {
                flex: 0 0 15rem;
            }
        }
    }

    &__discount-codes {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        border-bottom: 1px solid $color-grey-0;
        padding: 1rem 0;
    }

    &__discount-code {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 2rem;

        @include breakpoint-up-checkout {
            justify-content: flex-end;
        }

        &-label {
            text-align: left;
            padding-right: 2rem;
            font-size: 1.4rem;

            @include breakpoint-up-checkout {
                text-align: right;
            }
        }

        &-value {
            font-size: 1.4rem;
            text-align: right;
            font-weight: bold;
            flex: 0 0 10rem;

            @include breakpoint-up-checkout {
                flex: 0 0 15rem;
            }
        }
    }

    &__total {
        width: 100%;
        display: flex;
        align-items: baseline;
        padding: 2rem;
        justify-content: space-between;

        &:not(:last-child) {
            border-bottom: 1px solid $color-grey-0;
        }

        @include breakpoint-up-checkout {
            justify-content: flex-end;
        }

        &-label {
            text-align: left;
            padding-right: 2rem;
            font-size: 1.6rem;
            text-transform: uppercase;

            @include breakpoint-up-checkout {
                text-align: right;
            }
        }

        &-value {
            font-size: 1.6rem;
            text-align: right;
            font-weight: bold;

            @include breakpoint-up-checkout {
                flex: 0 0 15rem;
            }
        }
    }

    &__button {
        width: 100%;
        height: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-size: 1.4rem;
        color: $color-primary;
        font-weight: bold;
        letter-spacing: 0.15em;
        cursor: pointer;
    }
}
